<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";
import {required} from "vuelidate/lib/validators";
import Api from "@/apis/Api";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "PRODUCT LIST",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "PRODUCT LIST",
          active: true
        }
      ],
      checkbox:[],
      isCheckAll:false,
      productData: [],
        listSubCategoryData: [],
        vendorData: [],
        searchStr: "",
        paginationData: "",
        listSubCatagory: 0,
        brandData: 0,
      totalRows: 1,
        productStatus: 3,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100,250],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: "" },
        { key: "pimage", sortable: false, label: "PRODUCT IMAGE",thStyle: { color: "black", "font-size":"16px"} },
        { key: "title", sortable: true, label: "PRODUCT NAME",thStyle: { color: "black", "font-size":"16px",width:"340px"} },
        { key: "variants[0].sku", sortable: true, label: "PRODUCT SKU",thStyle: { color: "black", "font-size":"16px"}  },
        // { key: "listSubCategory", sortable: true, label: "PRODUCT TYPE",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "variants[0].price", sortable: true, label: "PRODUCT PRICE",thStyle: { color: "black", "font-size":"16px"}  },
        // { key: "coupon", sortable: true, label: "PRODUCT COUPONS" },
        { key: "variants[0].stock", sortable: true, label: "STOCK",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "status", sortable: true, label: "STATUS",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "action", label: "ACTION",thStyle: { color: "black", "font-size":"16px"}  }
      ],
      bulkImport:{
        csvFile:[]
      },
      paymentModeData: [],
      showmodal: false,
      showmodalPayment: false,
      submitted: false,
      submittedPayment: false,

      showRejectModel: false,
      submittedReject: false,
      loaderSpins: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */



    rows() {
      return this.paginationData;
    }
  },

  mounted() {
    // Set the initial number of items

      // this.pageData();
    this.loaderSpins = false
    this.totalRows = this.paginationData;

    // Master.productList({
    //   trash: 0
    // }).then((res) => {
    //   console.log(res.data.data.length)
    //     this.paginationData = res.data.data.length
    //   this.productData = res.data.data;
    // })
      this.pageData()

      Master.listSubCategoryList({
          trash: 0,
          status:"",
          subCategoryId: 0
      }).then((res) => {
          console.log(res)
          this.listSubCategoryData = res.data.data;
      })

      Master.vendorList({
          trash: 0
      }).then((res) => {
          console.log(res)
          this.vendorData = res.data.data;
      })


  },
  validations: {
    bulkImport:{
      csvFile:{required}
    },
  },

  methods: {

    viewProducts(data){
      localStorage.setItem("productId",data);
    },

    checkAll: function(){
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.productData) {
          this.checkbox.push(this.productData[key].id);
        }
      }
    },

      pageData(){
          this.getProducts();
        },

      getProducts(){
          Master.productListNew({
              searchStr:this.searchStr,
              trash: 0,
              listSubCategoryId:this.listSubCatagory,
              brandId:this.brandData,
              status:this.productStatus,
              page:this.currentPage
          }).then((res) => {
              console.log(res.data.data.length)
              this.paginationData = res.data.data.totalProductsCount
              this.activeProducts = res.data.data.activeProductsCount
              this.inActiveProducts = res.data.data.inActiveProductsCount
              this.totalProducts = res.data.data.totalProductsCount
              this.productData = res.data.data.products;
          })
      },
    onFileSelected(event){
      console.log(event.target.files)
      this.bulkImport.csvFile = event.target.files[0]
    },

    handleRejectedSubmit(){
      this.submittedReject = true;
      this.$v.$touch();
      if (this.$v.bulkImport.$invalid) {
        return;
      } else {
        this.loaderSpins = true
        this.paymentReject()
      }
    },

    rejectModal(){
      this.showRejectModel = true;
      this.loaderSpins = false
    },
    paymentReject(){
      const formData = new FormData()
      formData.append('file', this.bulkImport.csvFile)
      Master.importCSVFile(formData).then(res => {
        console.log(res)

        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
          this.getProducts()
        // this.successMessage = res.data.message;
        this.showRejectModel = false
        this.submittedReject = false
        this.loaderSpins = false

      }).catch((err) => {
        console.log(err)
        this.loaderSpins = false
      });


    },



    hideRejectModel(){
      this.showRejectModel = false
      this.submittedReject = false
    },
    async bulkActive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.productStatusActive({
          ids:this.checkbox,
          status: 1
        }).then((res) => {
          // this.successMessage = res.data.message;
          // this.$bvToast.toast(""+res.data.message+"", {
          //   title: `Success Message`,
          //   variant: 'success',
          //   solid: true
          // });

          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
            this.getProducts()
        })
      }

    },
    async bulkInactive(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        Master.productStatusInactive({
          ids: this.checkbox,
          status: 0
        }).then((res) => {
          // this.successMessage = res.data.message;
          // this.$bvToast.toast(""+res.data.message+"", {
          //   title: `Success Message`,
          //   variant: 'success',
          //   solid: true
          // });

          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          this.checkbox = []
          this.isCheckAll = false
            this.getProducts()
        })
      }
    },

      bulkExport(){
         let baseUrl = Api.defaults.baseURL
        window.open(baseUrl+'/export-products','_blank' )
      },

    async editProducts(data){
      localStorage.setItem("productId",data);
    },

    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.productBulkDelete({
            ids: this.checkbox,
            trash: 0
          }).then((res) => {
            // this.successMessage = res.data.message;
            // this.$bvToast.toast(""+res.data.message+"", {
            //   title: `Success Message`,
            //   variant: 'success',
            //   solid: true
            // });

            Vue.swal({
              position: "center",
              icon: "success",
              title: ""+res.data.message+"",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
              this.getProducts()
          })
        }
      }
    },

    async deleteProduct(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.productDelete(
              {
                ids: [data],
                trash: 0
                // trash: 0,
                // id: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
              this.getProducts()
          })
        }
      });
    }

      },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform:uppercase !important;">
          
          <div class="top_searchbuttons">
                <div class="btn-toolbar product_btns">
                  <router-link to="/product-add"> <a href="javascript:void(0);"  class="btn-sm btn-primary mb-2 mr-1" >
                    <i class="mdi mdi-plus mr-2"></i> Add
                  </a></router-link>
                  <a href="javascript:void(0);" class="btn-sm btn-outline-success mb-2 mr-1" @click="bulkActive">
                    <i class="mdi mdi-check mr-2"></i> Active
                  </a>
                  <a href="javascript:void(0);" class="btn-sm btn-outline-danger mb-2 mr-1"  @click="bulkInactive">
                    <i class="mdi mdi-cancel mr-2"></i> In-Active
                  </a>
                  <a href="javascript:void(0);" class="btn-sm btn-danger mb-2 mr-1" @click="bulkDelete">
                    <i class="mdi mdi-trash-can mr-2"></i> Delete
                  </a>
                  <router-link to="/import-products"> <a href="javascript:void(0);" class="btn-sm btn-success mb-2 mr-1">
                    <i class="mdi mdi-upload mr-2"></i> Import
                  </a></router-link>

                   <a href="javascript:void(0);" class="btn-sm btn-info mb-2 mr-1"  @click="bulkExport">
                        <i class="mdi mdi-download mr-2"></i> Export
                    </a>
                </div>
          </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="top_searchfiled">
    <div id="tickets-table_filter" class="dataTables_filter ">
                    <label class="d-inline-flex align-items-center">
                     
                      <b-form-input
                          v-model="searchStr"
                          type="search"
                          class="form-control form-control-sm ml-2"
                          placeholder="PRODUCT OR SKU"
                          @input="pageData"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="prodyc_select">
                    <select  v-on:change='pageData' v-model="listSubCatagory">
                      <option value="0">PRODUCT TYPE</option>
                      <option v-for="listSubCat in listSubCategoryData" :key="listSubCat.id" :value="listSubCat.id">{{listSubCat.name}}</option>
                    </select>
                  </div>

                   <div class="prodyc_select">
                     <select  v-on:change='pageData' v-model="brandData">
                       <option value="0">BRAND</option>
                       <option v-for="brand in vendorData" :key="brand.id" :value="brand.id">{{brand.name}}</option>
                     </select>
                   </div>

                  <div class="prodyc_select2">
                    <select v-on:change='pageData' v-model="productStatus">
                      <option :value="3">PRODUCT STATUS</option>
                      <option :value="1">ACTIVE</option>
                      <option :value="0">IN ACTIVE </option>
                    </select>
                  </div>
                  <!--<div class="button_search">-->
                    <!--<i class="fas fa-search"></i>-->
                  <!--</div>-->
                </div>
              </div>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-4">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-8">
                 <!--  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div> -->
                  <ul class="prodcust_countlist">
                    <li class="prod_list">Total  Count <span class="t_count">{{totalProducts}}</span></li>
                    <li class="active_list">Active  Count <span class="a_count">{{activeProducts}}</span></li>
                    <li class="inactive_list">In-active  Count <span class="ina_count">{{inActiveProducts}}</span></li>
                   
                  </ul>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered product_tablres"
                    :items="productData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    show-empty
                    sort-icon-left
                >
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>

                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"
                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>

                  <template v-slot:cell(variants[0].price)="row">
                    <p class="badge font-size-12" v-if="row.item.variants[0] != null">QAR {{row.item.variants[0].price.toLocaleString()}}</p>
                  </template>

                  <template v-slot:cell(variants[0].sku)="row">
                    <p v-if="row.item.variants[0] != null" class="badge font-size-12">
                      {{row.item.variants[0].sku}}
                    </p>

                  </template>

                  <template v-slot:cell(title)="row" >

                    <ul class="pl-1" style="list-style-type: none;">

                      <li>
                        <span class="badge prdt-onr-name"  style="white-space: pre-wrap; font-size: 65%">{{row.item.brand}}</span>
                      </li>
                      <li>
                        <span class="prdt-onr-name badge font-size-12" style="white-space: pre-wrap;">{{row.item.title}}</span>
                      </li>
                      <li>
                    <p class="prdt-onr-name badge font-size-12" style="white-space: pre-wrap;">
                     TYPE : {{row.item.listSubCategory}}
                    </p>
                      </li>
                    </ul>
                  </template>

<!--                  <template v-slot:cell(listSubCategory)="row">-->
<!--                    <p class="badge font-size-12">-->
<!--                      {{row.item.listSubCategory}}-->
<!--                    </p>-->
<!--                  </template>-->

                  <template v-slot:cell(variants[0].stock)="row">

                    <p class="badge font-size-12" v-if="row.item.variants[0] != null">
                      <span v-if="row.item.variants[0].stock > 0">{{row.item.variants[0].stock}}</span>
                      <span v-else-if="row.item.variants[0].stock <= 0 && row.item.preOrder == 1" class="badge-soft-primary badge font-size-9">PRE ORDER</span>
                      <span v-else-if="row.item.variants[0].stock <= 0 && row.item.preOrder == 0" class="badge-soft-danger badge font-size-9">OUT OF STOCK</span>
                    </p>

                  </template>

                  <template  v-slot:cell(pimage) = "data">
                    <router-link to="/product-details">
                      <img
                          v-if="data.item.variants[0] != null"
                          @click="viewProducts(data.item.id)"
                          :src="data.item.variants[0]?.images[0]?.imageUrl"
                        alt="product-img"
                        title="product-img"
                        class="avatar-md"
                    />
                    </router-link>

                  </template>
                  <template v-slot:cell(checkbox)="row">

                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>

                  </template>
                  <template v-slot:cell(action) = "data">
                    <router-link to="/product-details" :data-date="data.item.id"><a
                        href="javascript:void(0);"
                        class="mr-3 text-warning"
                        @click="viewProducts(data.item.id)"
                        v-b-tooltip.hover
                        title="VIEW"
                    >
                      <i class="mdi mdi-eye font-size-18"></i>
                    </a></router-link>
                    <router-link to="/product-edit" :data-date="data.item.id"><a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editProducts(data.item.id)"
                        v-b-tooltip.hover
                        title="EDIT"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a></router-link>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteProduct(data.item.id)"
                        v-b-tooltip.hover
                        title="DELETE"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"  v-on:input='pageData'></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="showRejectModel"
        title="IMPORT BULK PRODUCTS"
        title-class="text-dark font-18"
        hide-footer
        hide-header-close
        @close="hideRejectModel"
    >

      <form @submit.prevent="handleRejectedSubmit" enctype="multipart/form-data">
        <div class="form-group">
          <label for="name">EXCEL FILE *</label>
          <input type="file" accept=".xlsx, text/xlsx" class="form-control-file" @change="onFileSelected"  ref="files" multiple="multiple" :class="{ 'is-invalid': submittedReject && $v.bulkImport.csvFile.$error }">
          <div
              v-if="submittedReject && !$v.bulkImport.csvFile.required"
              class="invalid-feedback"
          >FILE IS REQUIRED</div>
        </div>


        <div class="text-right" >

          <div  v-if="loaderSpins">
            <b-spinner class="m-2" variant="dark" role="status"></b-spinner>
          </div>
          <div v-else>
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideRejectModel">CANCEL</b-button>
          </div>

        </div>

      </form>
      <br/>

    </b-modal>

  </Layout>
</template>
<style>
.text-breaker{
  width: 75%;
  word-break: break-word;
  display: block;
  text-align: left;
  white-space: pre-wrap;
  line-height: 1.25em;
}
</style>